@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');


.footer__wrapper
{
    width: 100vw;
    height: auto;
    background-color: black;
}
.footer
{
    width: 100%;
    height: 100%;
    display: flex;
    color: white;
    padding: 0 30px;
    justify-content: center;


}
.footer__content
{
    background-color: black;
    flex: 0.2;
    margin: 40px 20px;
    flex-direction: column;
}
.footer__content > .item1__container >div
{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.footer__content > .item1__container > div > .text
{
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}
.footer__content > .item1__container >.subtext
{
    padding: 20px 20px 20px 42px;
    color: white;
}
.footer__content > .footer__heading
{
    font-size: 20px;
    color: white;
    padding-bottom: 4px;
    border-bottom: 3px solid #ffd044;
    width: 50%;
    border-radius: 1px;
    font-family: 'Merriweather', serif;

}
.footer__content >.footer__items 
{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: rgb(232, 232, 232);
    margin: 15px 4px;
    transition: all 0.2s ease-in;

}
.footer__content >.footer__items:hover
{
    color: #ffd044;
    letter-spacing: 3px;
    cursor: pointer;
}
.footer__content>.social__icons__container>.social__icons
{
    display: inline-block;
    margin-right: 12px;
    font-size: 2rem;
    transition: all .2s ease;

}
.footer__content>.social__icons__container>.social__icons:hover
{
    color: #ffd044;
}
.footer__content>.social__icons__container
{
    margin-top: 10px;
}

@media (max-width: 500px) 
{
    .footer
    {
        display: block;
    

       
       
    }
    .footer__content>.social__icons__container
    {
        padding-bottom: 20px;
    }
    .footer__content
{
  
    padding: 40px 20px;
    margin: 0;
}
.footer__content > .item1__container
{
    display: none;
}

}


