@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap");

.portfolio__wrapper {
  padding: 3vw 5vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.portfolio__wrapper > .portfolio_items {
  width: 300px;
  height: 400px;
  border-radius: 20px;

  background-clip: content-box;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 15px;
}

.p1 {
  background: url(./Portfolio_images/1.jpg);
}

.p2 {
  background: url(./Portfolio_images/6.jpg);
}
.p3 {
  background: url(./Portfolio_images/3.jpg);
}
.portfolio_wrapper_button {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.Portfolio_button {
  border: 0;
  outline: none;
  padding: 15px 30px;
  background-color: #ffd044;
  font-size: 45px;
  height: 100px;
  font-family: "Playfair Display", serif;
  transition: 0.3s ease;
  width: 50vw;
  font-weight: bold;
  border-radius: 40px;
}

.Portfolio_button > span {
  color: #c72026;
}

.Portfolio_button:hover {
  cursor: pointer;
}
@media (max-width: 500px) {
  .Portfolio_button {
    font-size: 20px;

    border-radius: 20px;
  }
}
