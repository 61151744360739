@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
html {
  font-size: 15px;
}
.title {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 4rem;
}
.title > span {
  color: #ffd044;
}

.title__container {
  margin: 4em 0 0 0;
}
.subtitle {
  font-family: "Exo 2", sans-serif;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 3px;
  color: #c52026;
  margin-top: 8px;
}

@media (max-width: 500px) {
  html {
    font-size: 12px;
  }
}
