@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap");
.img__container {
  width: 100%;
  height: 95vh;
  position: relative;
  overflow: hidden;
}
.img__container > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
* {
  margin: 0;
  padding: 0;
}
.img__content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}
.img__content > p {
  font-size: 4em;
  font-family: "Kanit", sans-serif;
  color: #fff;
}
.img__content > p > span {
  color: rgb(255, 200, 0);
  transition: 0.3s ease;
}
.img__content > p > span:hover {
  letter-spacing: 8px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fadeInClass {
  animation-name: fadeIn;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@media (max-width: 500px) {
  .img__container {
    height: 60vh;
  }
  .img__content {
    text-align: center;
  }
}
