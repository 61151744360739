@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.about {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  font-size: 14px;
}

.about > .about__item1 {
  flex: 0.4;
  width: 200px;
}
.about > .about__item1 > img {
  width: 100%;
  box-sizing: border-box;
}
.about > .about__item2 {
  flex: 0.6;
}
.about > .about__item2 > .heading {
  font-family: "Playfair Display", serif;
  font-size: 3em;
  padding-bottom: 10px;
}
.about > .about__item2 > p {
  font-family: "Poppins", sans-serif;
  padding-right: 3em;
  font-size: 1.5em;
}
.about > .about__item2 > .heading > span {
  color: #ffd044;
}
.subtext
{
  margin-left: 60px;
}
@media (max-width: 500px) {
  .about {
    font-size: 12px;
    flex-direction: column;
  }
  .about__item1 {
    flex: 1;
    width: 100vw;
  }
  .about > .about__item2 > .heading {
    font-size: 2em;
  }
  .about__item1 > img {
    width: 100%;
  }
  .about > .about__item2 > p {
    flex: 1;
    text-align: justify;
    padding: 6px;
    font-size: 1em;
  }
  .about > .about__item2 > .heading {
    text-align: center;
  }
  .subtext
  {

    margin-left: 0px;
  }
}
